window.$modals = {
    show(name){
        window.dispatchEvent(
            new CustomEvent('showmodal', {
                 detail: name
            })
        );
    },

    hide(name){
        window.dispatchEvent(
            new CustomEvent('hidemodal', {
                detail: name
            })
        );
    },
}
